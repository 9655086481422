import React from "react"
import { arrayOf, shape, string } from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import Fade from "react-reveal/Fade"

const propTypes = {
  data: arrayOf(
    shape({
      title: string.isRequired,
      link: string,
    })
  ).isRequired,
}

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.currentColours.fg};
  &:after {
    margin-left: 0.5vw;
    content: "-";
  }
`

const BreadCrumbList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
  }
`

const BreadCrumbListItem = styled.li`
  color: ${({ theme }) => theme.currentColours.fg};
  font-size: 90%;
  line-height: 1.1818181818181819em;
  letter-spacing: 0.1em;
  text-decoration: none;
  margin-right: 0.5vw;
  margin-bottom: 0;
  @media ${props => props.theme.breakpoint.desktop} {
    font-size: 70% !important;
  }
  span {
    font-weight: 600;
  }
`

const Breadcrumbs = ({ data: breadcrumbs }) => {
  return (
    <Fade delay={500} duration={800}>
      <BreadCrumbList>
        {breadcrumbs &&
          breadcrumbs.length > 0 &&
          breadcrumbs.map(b => {
            return (
              <BreadCrumbListItem key={b.link}>
                {b.link ? (
                  <BreadcrumbLink to={b.link}>{b.title}</BreadcrumbLink>
                ) : (
                  <span>{b.title}</span>
                )}
              </BreadCrumbListItem>
            )
          })}
      </BreadCrumbList>
    </Fade>
  )
}

Breadcrumbs.propTypes = propTypes

export default Breadcrumbs
