import React from "react"
import { arrayOf, shape, string } from "prop-types"
import { Location } from "@reach/router"
import { Flex, Box } from "rebass"
import styled from "@emotion/styled"
import BreadCrumbs from "./breadcrumbs"
import SearchWrapper from "./search-wrapper"

const propTypes = {
  breadcrumbs: arrayOf(
    shape({
      title: string.isRequired,
      link: string,
    })
  ).isRequired,
}

const StyledBox = styled(Box)`
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    width: auto;
  }
`
const StyledFlex = styled(Flex)`
  margin: -16px 0 3vw 0;
  align-items: center;
`
const SearchContainer = styled(Box)`
  margin-left: auto;
  justify-self: flex-end;
`

const ProductsHeader = ({ breadcrumbs }) => {
  return (
    <>
      <Location>
        {({ location }) => (
          <StyledFlex flexWrap="wrap">
            <StyledBox width={[1]}>
              <BreadCrumbs data={breadcrumbs} />
            </StyledBox>
            <SearchContainer>
              <SearchWrapper location={location} fieldOnly />
            </SearchContainer>
          </StyledFlex>
        )}
      </Location>
    </>
  )
}

ProductsHeader.propTypes = propTypes

export default ProductsHeader
