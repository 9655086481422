import React from "react"
import { shape, array, string } from "prop-types"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Flex, Box } from "rebass"
import Fade from "react-reveal/Fade"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/atoms/page-title"
import theme from "../config/theme"
import Card from "../components/molecules/card"
import ProductsHeader from "../components/molecules/products-header"
import { getFallbackImageFluid } from "../helpers/index"

const propTypes = {
  intl: shape().isRequired,
  data: shape({
    contentfulCategory: shape({
      title: string,
    }),
    allContentfulProduct: shape({
      edges: array,
    }),
  }).isRequired,
  pageContext: shape().isRequired,
  path: string.isRequired,
}

const Category = ({ intl, data, pageContext, path, location }) => {
  const { languages: slugs } = pageContext
  const { locale } = intl
  const { title } = data.contentfulCategory
    ? data.contentfulCategory
    : data.contentfulSubCategory
  const { edges } = data.categoryProducts.edges.length
    ? data.categoryProducts
    : data.subCategoryProducts
  const products = edges.filter(
    i => i.node.node_locale === locale && i.node.title
  )

  const breadcrumbs = []
  breadcrumbs.push({
    title: intl.formatMessage({ id: "catalogue" }),
    link: intl.formatMessage({ id: "cat_path" }),
  })
  breadcrumbs.push({
    title,
    link: null,
  })

  return (
    <Layout
      slugs={slugs}
      colourTheme="mercury"
      currentPath={path}
      hasProductsHeader
    >
      <SEO title={title} location={location.pathname} />
      <ProductsHeader breadcrumbs={breadcrumbs} />
      <Fade delay={300} duration={800}>
        <PageTitle>{title}</PageTitle>
      </Fade>
      <Flex mx={theme.flexGutter} flexWrap="wrap">
        {products &&
          products.map(({ node: p }, index) => (
            <Box
              width={[1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 4]}
              px={theme.boxGutter}
              key={p.id}
            >
              <Fade
                force={true}
                bottom
                distance="3em"
                duration={600}
                delay={100 * index}
              >
                <Card
                  slug={p.slug}
                  title={p.title}
                  fallbackFluid={getFallbackImageFluid(edges, p.contentful_id)}
                  body={p.body}
                />
              </Fade>
            </Box>
          ))}
      </Flex>
    </Layout>
  )
}

export default injectIntl(Category)

Category.propTypes = propTypes

export const categoryQuery = graphql`
  query($slug: String!, $contentful_id: String!) {
    contentfulCategory(slug: { eq: $slug }) {
      title
    }
    contentfulSubCategory(slug: { eq: $slug }) {
      title
    }
    categoryProducts: allContentfulProduct(
      filter: {
        categories: {
          elemMatch: {
            category: { elemMatch: { contentful_id: { eq: $contentful_id } } }
          }
        }
      }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          slug
          node_locale
          body {
            ... on contentfulProductBodyRichTextNode {
              id
              json
            }
          }
          image {
            fluid(maxWidth: 768, maxHeight: 768) {
              ...GatsbyContentfulFluid
            }
          }
          categories {
            title
            category {
              subCategory {
                title
              }
            }
          }
        }
      }
    }
    subCategoryProducts: allContentfulProduct(
      filter: {
        categories: { elemMatch: { contentful_id: { eq: $contentful_id } } }
      }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          slug
          node_locale
          body {
            ... on contentfulProductBodyRichTextNode {
              id
              json
            }
          }
          image {
            fluid(maxWidth: 768, maxHeight: 768) {
              ...GatsbyContentfulFluid
            }
          }
          categories {
            title
            category {
              subCategory {
                title
              }
            }
          }
        }
      }
    }
  }
`
